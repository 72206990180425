import {IUser} from "../../model/User";
import React, {useEffect, useMemo, useState} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import {Button, Image, Modal} from "react-bootstrap";
import WatchlistService from "../../service/WatchlistService";
import toast from "react-hot-toast";
import AuthService from "../../service/auth/AuthService";
import Row from "react-bootstrap/Row";
import avatar from "../../assets/images/user/default_avatar.png"
import UserService from "../../service/UserService";
import UserConnectionsService from "../../service/UserConnectionsService";


const ConnectionComponent = (props: any) => {

    const [connections, setConnections] = useState<IUser[]>(props.connections as IUser[]);
    const [removeModalShow, setRemoveModalShow] = useState(false);
    const [currentUser, setCurrentUser] = useState<IUser>();
    const [user, setUser] = useState<IUser>(AuthService.getCurrentUser() ?? {});
    const [showContent, setShowCOntent] = useState(true);

    useEffect(() => {
        setConnections(props.connections as IUser[])
    }, [props])


    function RenderConnections() {
        const listItems = connections?.map((u: IUser) =>
            <Row className="g-2" key={u.id}>
                <ListGroup className="g-2" key={u.id}>
                    <h6 className="ms-1">Following {connections.length} user{(connections.length === 1) ? '' : 's'}</h6>
                    <ListGroup.Item
                        className="d-flex flex-row justify-content-between mb-2 align-items-center"
                        action
                        href={'/user/' + u.id}
                        onClick={(e) => e.stopPropagation()}
                    >

                        <Col sm={1}>
                            <Image src={u.thumbnail ?? avatar} roundedCircle height={36}/>
                        </Col>
                        <Col sm={3}>
                            <div className="fw-bold ">{u.firstName} {u.lastName}</div>
                            {/*Cras justo odio*/}
                        </Col>

                        <Col sm={3}>@{u.username}</Col>

                        <Col sm={5} className="d-flex justify-content-end">
                            <Button variant="outline-danger" size="sm"
                                    onClick={(event) => {
                                        event.preventDefault()
                                        handleRemoveModalShow(u)
                                    }}
                            >Remove</Button>
                            <Button variant="success" size="sm" className="mx-2">Message</Button>
                        </Col>

                    </ListGroup.Item>
                </ListGroup>
            </Row>
        );

        return <ListGroup>{listItems}</ListGroup>;
    }


    const handleRemoveModalClose = () => setRemoveModalShow(false);

    const handleRemoveModalShow = (u: IUser) => {
        setRemoveModalShow(true)
        setCurrentUser(u)
    };


    function removeConnection(u:IUser) {
        UserConnectionsService.removeFollowing(u.id).then(resp => {
            setConnections(oldValues => {
                return oldValues?.filter(old => old.id !== u.id)
            })
            toast.success(String(u.firstName + " " + u.lastName + " was removed!"))
        })
            .finally(handleRemoveModalClose)
    }


    return (
        <>
            <Modal show={removeModalShow} centered onHide={handleRemoveModalClose} onClick={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to
                    remove <b>{currentUser?.firstName} {currentUser?.lastName}</b> from
                    the list?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => removeConnection(currentUser!)}>
                        Remove
                    </Button>
                    <Button variant="secondary" onClick={handleRemoveModalClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            {connections.length > 0 ? RenderConnections() :
                <div className="text-center my-5 py-5">
                    <div className="display-6 mb-3">You do not have any connections yet.</div>
                    <p>Start following others so they appear here.</p>
                </div>
            }
        </>
    )
}

export default ConnectionComponent;