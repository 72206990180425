import axiosClient from "./axios/AxiosClient";
import {IPosition} from "../model/Positions";
import {IStockOrder} from "../model/Stock";
import toast from "react-hot-toast";

const STOCK_DATA_URL = process.env.REACT_APP_STOCK_DATA_URL;
const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;


class StockTradeService {

    submitTrade(order: IStockOrder) {
        return axiosClient.post(STOCKS_URL + "/order/create", order)
     /*       .catch(error => {
                switch (error.response.status) {
                    case 401:
                        console.log("Username and/or password are incorrect!")
                        toast.error("Username and/or password is incorrect!")
                        break;
                    case 403:
                        console.log("Forbidden")
                        toast.error("Access denied. Email address is not confirmed.")
                        break;
                    case 422:
                        console.log("Ins funds")
                        toast.error("Ins funds")
                        break;
                    default:
                        console.log(error)
                        toast.error(error)
                }

            })*/
    }


}

export default new StockTradeService();
