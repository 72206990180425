import parse from 'html-react-parser';
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import {CardBody, CardTitle, Tab, Table, Tabs} from "react-bootstrap";
import StockService from "../../service/StockService";
import StockProfile from "./profile/StockProfile";
import StockFeedComponent from "./feed/StockFeedComponent";
import StockHeaderComponent from "./header/StockHeaderComponent";
import {useQuery} from "@tanstack/react-query";
import Layout2 from "../layout/Layout2";
import Row from "react-bootstrap/Row";
import StockTopGainersComponent from "./StockTopGainersComponent";
import {IStockFinancials} from "../../model/StockFinancials";
import Col from "react-bootstrap/Col";


const StockPage = () => {

        const {symbol} = useParams();
        const stockQuery = useQuery({queryKey: ['stock'], queryFn: () => StockService.getBySymbol(symbol ?? '')})
        const stock = stockQuery.data?.data
        const [stockFinancials, setStockFinancials] = useState<IStockFinancials>();


        useEffect(() => {
            stock && (document.title = stock?.name + " (" + stock?.name.toUpperCase() + ") - Finmates.com");
        }, [stock]);


        useEffect(() => {
            StockService.getStockFinancials(symbol ?? '').then(resp => setStockFinancials(resp.data))
        }, []);

        const content =
            <div className="text-wrap mb-3">

                {stock &&
                    <StockHeaderComponent {...stock}/>
                }
                <Card className="mb-2">
                    <Card.Body className="p-0">

                        <Tabs defaultActiveKey="summary" className="mb-2" fill>
                            <Tab eventKey="summary" title="Summary">

                                <Row className="g-0 pt-0">
                                    <Col sm={6}>
                                        <Table striped bordered hover>
                                            <tbody>
                                            {stock?.name &&
                                                <tr>
                                                    <td>Company name</td>
                                                    <td>{parse(stock.name)}</td>
                                                </tr>
                                            }
                                            {stock?.exchange &&
                                                <tr>
                                                    <td>Stock Exchange</td>
                                                    <td>{stock.exchange}</td>
                                                </tr>
                                            }
                                            {stock?.industry &&
                                                <tr>
                                                    <td>Industry</td>
                                                    <td>{stock.industry}</td>
                                                </tr>
                                            }
                                            {stock?.employerIdNumber &&
                                                <tr>
                                                    <td>Employer Id Number</td>
                                                    <td>{stock.employerIdNumber}</td>
                                                </tr>
                                            }
                                            </tbody>
                                        </Table>
                                    </Col>

                                    <Col sm={6}>
                                        <Table striped bordered hover>
                                            <tbody className="w-100">
                                            <tr>
                                                <td>Day min</td>
                                                <td>$ {stockFinancials?.minDayPrice}</td>
                                            </tr>

                                            <tr>
                                                <td>Day max</td>
                                                <td>$ {stockFinancials?.maxDayPrice}</td>
                                            </tr>

                                            <tr>
                                                <td>52 week low</td>
                                                <td>$ {stockFinancials?.min52weeks}</td>
                                            </tr>

                                            <tr>
                                                <td>52 week high</td>
                                                <td>$ {stockFinancials?.max52weeks}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>


                            </Tab>
                            <Tab eventKey="profile" title="Profile">{stock &&
                                <StockProfile {...stock}></StockProfile>
                            }
                            </Tab>
                            <Tab eventKey="holders" title="Holders">Tab content for Holders Tab</Tab>
                            <Tab eventKey="history" title="Historical Data" disabled>Tab content for Historical
                                Data</Tab>
                        </Tabs>

                    </Card.Body>
                </Card>

                <Card className="mb-2">
                    <Card.Body>
                        {stock &&
                            <StockFeedComponent {...stock}/>
                        }
                    </Card.Body>
                </Card>
            </div>

        const right =
            <StockTopGainersComponent stock={stock}/>

        return (
            <>
                <Layout2 main={content} right={right} displayRight={true}>

                </Layout2>
            </>
        );
    }
;


export default StockPage;