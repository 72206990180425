import axios from 'axios';
import axiosClient from "./axios/AxiosClient";
import useAxios from "../hooks/useAxios";
import {IUser, IUserRegistrationFormDTO} from "../model/User";
import authHeader from "./auth/auth-header";
import toast from "react-hot-toast";
import AuthService from "./auth/AuthService";


const BASE_URL = process.env.REACT_APP_BASE_URL;
const STOCKS_URL = process.env.REACT_APP_STOCKS_URL;

class UserService {


/*
    getUserPromise() {
        return axiosClient.get<IUser>(BASE_URL + '/user')
            .then(response => localStorage.setItem("user", JSON.stringify(response?.data)))
    }
*/



    getUser(id = 0) {
        console.log("getUser id =", id)
        const resp = useAxios({
            method: "GET",
            url: (id === 0) ? BASE_URL + "/user" : BASE_URL + "/user/id/" + id,
        });

        if (id === 0)
            localStorage.setItem("user", JSON.stringify(resp.response?.data))
        return resp;
    }


    async update(user: IUser) {

        user.refreshToken = JSON.parse(localStorage.getItem("refresh_token") ?? "");

        await axiosClient
            .post(BASE_URL + "/user/update", user)
            .then((response) => {
                    const accessToken = response.data
                    localStorage.setItem("access_token", accessToken)
                    toast.success("User info updated.")
                }
            )
            .catch(error => {
                    toast.error("User update error.")
                    //console.log("Update user error: " + String(error))
                    //return Promise.reject(error.data)
                }
            )

    }


    async updatePassword(currentPassword: string, newPassword: string) {
        const resp = await axiosClient.post(BASE_URL + "/user/update-password",
            {current_password: currentPassword, new_password: newPassword})
            .then(() => toast.success("Password updated!"))
            .catch(error => {
                console.log("error :: " + error.message);
                //toast.error("Error: " + error.response.message);
            })


    }


   /* followUser(id: number) {
        return axiosClient.put(BASE_URL + '/user/follow', {id: id});
    }

    unfollowUser(id: number) {
        return axiosClient.put(BASE_URL + '/user/unfollow', id);
    }


    async isFollowing(id: number) {
        let resp = {data: false};
        if (AuthService.getCurrentUser().id === id)
            resp.data = false;
        else
            resp = await axiosClient.get(BASE_URL + '/user/following/' + id)

        return resp;
    }

    //confirmed following
    getConfirmedFollowing(id: number) {
        return axiosClient.get(BASE_URL + '/user/following/confirmed/' + id)
    }

    getFollowingConnectionRequests(id: number) {
        return axiosClient.get(BASE_URL + '/user/following/req/' + id)
    }

    getFollowersConnectionRequests(id: number) {
        return axiosClient.get(BASE_URL + '/user/followers/req/' + id)
    }

    //confirmed followers: users that follow given user
    getConfirmedFollowers(id: number) {
        return axiosClient.get(BASE_URL + '/user/followers/confirmed/' + id)
    }


    getFollowingCount(id: number) {
        return axiosClient.get(BASE_URL + '/user/following/count/' + id)
    }


    getFollowersCount(id: number) {
        return axiosClient.get(BASE_URL + '/user/followers/count/' + id)
    }

    getMutualConnections(id: number) {
        return axiosClient.get(BASE_URL + '/user/connections/mutual/' + id)
    }

    getMutualConnectionsCount(id: number) {
        return axiosClient.get(BASE_URL + '/user/connections/mutual/count/' + id)
    }*/


}

export default new UserService();
