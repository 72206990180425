import React from "react"
import './Footer.css';
import {now} from "moment";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {FaListUl, FaRegUser} from "react-icons/fa";
import AuthService from "../../../service/auth/AuthService";
import Nav from 'react-bootstrap/Nav';



function Footer() {
    return (
        <>
            <Navbar className="d-none d-md-inline bg-body-secondary mt-3">
                <div className="justify-content-center w-100">
                    <Nav className=" flex-nowrap justify-content-center gap-2 d-flex flex-row border-bottom border-secondary mx-5">
                            <Nav.Link href="/home" className="px-2 text-muted">Home</Nav.Link>
                            <Nav.Link href="/privacy-policy" className="px-2 text-muted">Privacy Policy</Nav.Link>
                            <Nav.Link href="/terms-and-conditions" className="px-2 text-muted">Terms & Conditions</Nav.Link>
                        <Nav.Link href="/faq" className="px-2 text-muted">FAQs</Nav.Link>
                        <Nav.Link href="/about" className="px-2 text-muted">About</Nav.Link>
                        </Nav>
                        <p className="text-center text-muted py-1 my-0">© {new Date(now()).getFullYear()} FinMates Inc</p>
                    </div>
            </Navbar>
            {AuthService.isLoggedIn() &&
                <Navbar className="d-md-none d-flex justify-content-around text-body bg-body-secondary mt-3" sticky="bottom">
                    <div className="text-center small xx-small">
                        <div><FaRegUser size={18}/></div>
                        <div><small className="x-small">Account</small></div>
                    </div>
                    <div className="text-center">
                        <div><FaRegUser size={18}/></div>
                        <div><small className="x-small">Stocks</small></div>
                    </div>
                    <div className="text-center">
                        <div><FaRegUser size={20}/></div>
                        <div><small className="x-small">Messages</small></div>
                    </div>
                    <div className="text-center">
                        <div><FaRegUser size={20}/></div>
                        <div><small className="x-small">Subscriptions2</small></div>
                    </div>
                </Navbar>
            }

        </>
    );
}

export default Footer;
