import {Link, useNavigate} from "react-router-dom";
import React from "react";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Col from "react-bootstrap/Col";
import LayoutEmpty from "../../../components/layout/LayoutEmpty";

export default function PrivacyPolicyPage() {

    function goBack() {
        const navigate = useNavigate();

        return <button onClick={() => navigate(-1)}>go back</button>
    }


    return (
        <LayoutEmpty center>

            <Row className="g-4 py-3">
                <Col className="col-lg-8 mx-auto">

                    <Card>
                        <CardHeader>
                            <h1 className="card-title h4 mb-0">Privacy Policy</h1>
                        </CardHeader>
                        <Card.Body>

                            <h5>Your privacy matters</h5>
                            <p>At Finmates, we prioritize the privacy and security of our
                                users&apos; personal information.
                                This Privacy Policy explains how we collect, use, store, and share data when you use our
                                application.</p>


                            <hr className="mt-2 mb-4"/>

                            <h5>Information We Collect</h5>
                            <p>When you use our application and authenticate through Google OAuth2 API services, we may
                                collect the following information:
                            </p>
                            <ul>
                                <li>
                                    <b>Basic Profile Information:</b> This may include your name, email address, profile
                                    picture, and other publicly available information associated with your Google
                                    account.
                                </li>
                                <li>
                                    <b>Access Tokens:</b> We may collect access tokens provided by Google OAuth2 API
                                    services to authenticate your identity and access specific Google services on your
                                    behalf.
                                </li>
                            </ul>
                            <hr className="mt-2 mb-4"/>


                            <h5>How We Use Your Information</h5>

                            <p>We do not sell, trade, or rent your personal information to third parties for marketing
                                purposes. However, we may share your information with trusted third-party service
                                providers who assist us in operating our website, conducting our business, or servicing
                                you, as long as those parties agree to keep this information confidential. </p>

                            <p>We use the information collected through Google OAuth2 API services for the following
                                purposes: </p>

                            <ul>
                                <li>
                                    <b>Authentication:</b> We use the access tokens provided by Google OAuth2 API
                                    services to
                                    authenticate your identity and enable access to our application&apos;s features and
                                    services.
                                </li>
                                <li>
                                    Personalization: We may use your basic profile information to personalize your
                                    experience within our application, such as displaying your name or profile picture.
                                </li>
                            </ul>

                            <hr className="mt-2 mb-4"/>

                            <h5>How We Store Your Information</h5>
                            <p>We implement a variety of security measures to maintain the safety of your personal
                                information when you access your account, submit sensitive information. We use
                                encryption technology to protect the transmission of data on our website and regularly
                                update our security protocols to safeguard against unauthorized access. </p>

                            <p>
                                We also take appropriate measures to securely store the information collected through
                                Google
                                OAuth2 API services. This includes encryption, access controls, and other security
                                measures to protect your data from unauthorized access, disclosure, alteration, or
                                destruction.
                            </p>


                            <h5>Sharing Your Information</h5>
                            <p>We do not share your information collected through Google OAuth2 API services with third
                                parties, except in the following circumstances:</p>

                            <ul>
                                <li>
                                    <b>With Your Consent:</b> We may share your information with third parties if you
                                    have provided consent for such sharing
                                </li>
                                <li>
                                    <b>For Legal Reasons:</b> We may share your information if required to do so by law
                                    or in response to a valid legal request.
                                </li>
                                <br/>
                                <em>
                                    You can control the information you share through Google OAuth2 API services by
                                    managing
                                    your Google account settings and permissions. You can revoke access to our
                                    application
                                    or adjust permissions at any time through your Google account settings.
                                </em>
                            </ul>

                            <hr className="mt-2 mb-4"/>


                            <h5>Data Retention</h5>
                            <p>We retain the information collected through Google OAuth2 API services only for as long
                                as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer
                                retention period is required or permitted by law.</p>

                            <hr className="mt-2 mb-4"/>


                            <h5>Cookies</h5>
                            <div className="alert alert-warning" role="alert">
                                This website stores cookies on your computer.
                            </div>
                            <p>We use cookies to enhance your experience on our website by remembering your preferences
                                and optimizing site functionality. You can choose to disable cookies through your
                                browser settings, although this may affect certain features of the site. </p>


                            <hr className="mt-2 mb-4"/>

                            <h5>Changes to Privacy Policy</h5>
                            <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes
                                will be posted on this page along with the updated revision date. </p>


                            <hr className="mt-2 mb-4"/>

                            <h5>Contact Us</h5>
                            <p>If you have any questions or concerns about our Privacy Policy or the handling of your
                                personal information, please contact us.
                            </p>
                            <p>By using our website, you consent to the terms outlined in this Privacy Policy. </p>


                        </Card.Body>
                    </Card>

                </Col>
            </Row>

        </LayoutEmpty>
    )


}
