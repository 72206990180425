import React, {useEffect, useMemo, useState} from "react";
import {Badge, CardBody, Form, Tabs} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Layout from "../layout/Layout";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import {IUser} from "../../model/User";
import AuthService from "../../service/auth/AuthService";
import FollowingConnectionsComponent from "./FollowingConnectionsComponent";
import FollowersConnectionsComponent from "./FollowersConnectionsComponent";
import FollowingReqConnectionsComponent from "./FollowingReqConnectionsComponent";
import FollowersReqConnectionsComponent from "./FollowersReqConnectionsComponent";
import MutualConnectionsConnectionsComponent from "./MutualConnectionsConnectionsComponent";
import UserConnectionsService from "../../service/UserConnectionsService";
import UserService from "../../service/UserService";


export default function ConnectionsPage(props: any) {

    interface IFilter {
        name: string,
        symbol: string,
    }

    const [user, setUser] = useState<IUser>(AuthService.getCurrentUser() ?? UserService.getUser(0).response?.data);
    const [following, setFollowing] = useState<IUser[]>([]);
    const [followers, setFollowers] = useState<IUser[]>([]);
    const [followingReq, setFollowingReq] = useState<IUser[]>([]);
    const [mutualConnections, setMutualConnections] = useState<IUser[]>([]);
    const [followersReq, setFollowersReq] = useState<IUser[]>([]);
    const [filter, setFilter] = useState<IFilter>({name: '', symbol: ''});

    //const user: IUser = UserService.getUser(id).response?.data;


    useEffect(() => {
        UserConnectionsService.getConfirmedFollowing(user?.id)
            .then(response => setFollowing(response.data))
        console.log("following :: " + following.length)

        UserConnectionsService.getConfirmedFollowers(user?.id)
            .then(response => setFollowers(response.data))
        console.log("followers :: " + followers)

        UserConnectionsService.getFollowingConnectionRequests(user?.id)
            .then(response => setFollowingReq(response.data))
        console.log("following requests:: " + followingReq)

        UserConnectionsService.getFollowersConnectionRequests(user?.id)
            .then(response => setFollowersReq(response.data))
        console.log("followers requests:: " + followersReq)

        UserConnectionsService.getMutualConnections(user?.id)
            .then(response => setMutualConnections(response.data))
        console.log("mutual connections:: " + mutualConnections)
    }, [user])


    const filteredFollowing: IUser[] = useMemo(() => {
        return following.filter(c => c.firstName.toLowerCase().includes(filter.name.toLowerCase()) ||
            c.lastName.toLowerCase().includes(filter.name.toLowerCase()) ||
            c.username.toLowerCase().includes(filter.name.toLowerCase()))
    }, [following, filter]);

    const filteredFollowers: IUser[] = useMemo(() => {
        return followers.filter(c => c.firstName.toLowerCase().includes(filter.name.toLowerCase()) ||
            c.lastName.toLowerCase().includes(filter.name.toLowerCase()) ||
            c.username.toLowerCase().includes(filter.name.toLowerCase()))
    }, [followers, filter]);

    const filteredFollowingReq: IUser[] = useMemo(() => {
        return followingReq.filter(c => c.firstName.toLowerCase().includes(filter.name.toLowerCase()) ||
            c.lastName.toLowerCase().includes(filter.name.toLowerCase()) ||
            c.username.toLowerCase().includes(filter.name.toLowerCase()))
    }, [followingReq, filter]);

    const filteredFollowersReq: IUser[] = useMemo(() => {
        return followersReq.filter(c => c.firstName.toLowerCase().includes(filter.name.toLowerCase()) ||
            c.lastName.toLowerCase().includes(filter.name.toLowerCase()) ||
            c.username.toLowerCase().includes(filter.name.toLowerCase()))
    }, [followersReq, filter]);


    const handleChange = (value: object) => {
        /*    let updatedValue = {};
            updatedValue = {value};*/
        setFilter(filter => ({
            ...filter,
            ...value
        }));
    }


    const content =
        <>
            <Row className="g-3">
                <Col className="col-12 col-md-9">

                    <Card>
            {/*            <CardHeader>
                            <h6>My Connections</h6>
                        </CardHeader>*/}

                        <CardBody className="m-0">

                            {/*<Tabs
                                defaultActiveKey="following"
                                id="connections-tabs"
                                className="mb-3 pb-3"
                                variant="pills"
                                justify
                            >

                                <Tab eventKey="following" title="Following">
                                        <FollowingConnectionsComponent connections={filteredFollowing}/>
                                </Tab>

                                <Tab eventKey="followers" title="Followers">
                                    <FollowersConnectionsComponent connections={filteredFollowers}/>
                                </Tab>


                                    <Tab eventKey="outgoing"
                                         title={<div className="text-nowrap">Following requests &nbsp;<Badge
                                             bg="danger">{filteredFollowingReq?.length}</Badge></div>}>
                                        <FollowingReqConnectionsComponent connections={filteredFollowingReq}/>
                                    </Tab>


                                    <Tab eventKey="incoming"
                                         title={<div className="text-nowrap">Followers Requests &nbsp;<Badge
                                             bg="danger">{filteredFollowersReq?.length}</Badge></div>}>
                                        <FollowersReqConnectionsComponent connections={filteredFollowersReq}/>
                                    </Tab>*/}

                            <Tabs
                                defaultActiveKey="following"
                                id="connections-tabs"
                                className="mb-3"
                                variant="tabs"
                                justify
                            >

                                <Tab eventKey="following" title={<div className="text-nowrap">Following {following.length} &nbsp;<Badge
                                    bg="danger">{filteredFollowingReq?.length}</Badge></div>}>
                                    <FollowingReqConnectionsComponent connections={filteredFollowingReq}/>
                                    <FollowingConnectionsComponent connections={filteredFollowing}/>
                                </Tab>


                                <Tab eventKey="followers" title={<div className="text-nowrap">Followers {followers.length} &nbsp;<Badge
                                    bg="danger">{filteredFollowersReq?.length}</Badge></div>}>
                                    <FollowersReqConnectionsComponent connections={filteredFollowersReq}/>
                                    <FollowersConnectionsComponent connections={filteredFollowers}/>
                                </Tab>


                                <Tab eventKey="contact" title={<div className="text-nowrap">Connections
                                    {mutualConnections.length}</div>}>
                                    <MutualConnectionsConnectionsComponent connections={mutualConnections}/>
                                </Tab>

                            </Tabs>

                        </CardBody>
                    </Card>

                </Col>

                <Col className="col-md-3">
                    <Card>
                        {/*<CardHeader>
                            <h6>Connections filter</h6>
                        </CardHeader>*/}
                        <CardBody>
                            <Form>
                                <Form.Control
                                    type="password"
                                    id="inputPassword5"
                                    aria-describedby="passwordHelpBlock"
                                />
                                <Form.Text id="passwordHelpBlock" muted>
                                    first, last or username
                                </Form.Text>

                                <Row className="align-items-center">
                                    <Col xs="auto">
                                        <Form.Label htmlFor="stockNameFilter">
                                            Name
                                        </Form.Label>
                                        <Form.Control
                                            className="my-1"
                                            name="stockNameFilter"
                                            id="stockNameFilter"
                                            placeholder="first, last or username"
                                            value={filter.name}
                                            onChange={event => handleChange({name: event.target.value})}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Form.Label htmlFor="stockSymbolFilter">
                                            Symbol
                                        </Form.Label>
                                        <Form.Control
                                            className="mb-3"
                                            name="stockSymbolFilter"
                                            id="stockSymbolFilter"
                                            placeholder="users that hold stock"
                                            value={filter.symbol}
                                            onChange={event => handleChange({symbol: event.target.value})}
                                        />
                                    </Col>
                                </Row>
                            </Form>

                        </CardBody>
                    </Card>

                </Col>
            </Row>


        </>

    return (
        <Layout>
            {content}
        </Layout>
    )

}