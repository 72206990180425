import React, {useContext} from 'react';
import Container from 'react-bootstrap/Container';
import './Header.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Button, Image, NavDropdown, NavItem} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import Col from "react-bootstrap/Col";
import Offcanvas from 'react-bootstrap/Offcanvas';
import SearchHeaderComponent from "../../Search/SearchHeaderComponent";
import Row from "react-bootstrap/Row";
import AuthService from "../../../service/auth/AuthService";
import {IUser} from "../../../model/User";
import {
    BsCloudMoon,
    BsMoonFill,
    BsMoonStars,
    BsMoonStarsFill,
    BsStars,
    BsSun,
    BsSunFill,
    BsSunrise
} from "react-icons/bs";
import {ThemeContext} from "../../../App";
import {useCookies} from "react-cookie";
import {PiSunBold} from "react-icons/pi";
import OffcanvasMenu from "./OffcanvasMenu";


export default function Header() {
    const context = useContext(ThemeContext);
    const [themeCookies, setThemeCookie, removeThemeCookie] = useCookies(['theme']);
    const navigate = useNavigate();
    const user: IUser = AuthService.getCurrentUser();

    /*    const {keycloak, initialized} = useKeycloak()*/


    function logout() {
        AuthService.logout();
        navigate("/")
    }


    return (

        <Navbar collapseOnSelect sticky="top" expand="lg" bg="dark" variant="dark">
            <Container fluid="lg">
                <Row className="flex-fill">
                    <Col className="col-2 col-md-4 d-flex align-items-center">
                        <Link to="/"><Image src="/icons/logo.png" width={33}
                                            alt="Finmates.com - Empowering financial data sharing"/></Link>&nbsp;&nbsp;
                        <Navbar.Brand className="d-none d-md-inline-flex" href="/">FinMates</Navbar.Brand>
                    </Col>


                    <Col className="col-8 col-md-4 d-flex justify-content-center " style={{position: "relative"}}>
                        <SearchHeaderComponent/>
                    </Col>


                    <Col className="col-2 col-md-4 d-flex ms-auto">

                        <Nav className="d-flex d-inline-flex ms-auto">
                            <Nav.Link className="d-none d-lg-inline-block align-content-center me-2"
                                      onClick={() => {
                                          const newTheme = context?.theme === 'dark' ? 'light' : 'dark'
                                          context?.setTheme(newTheme)
                                          removeThemeCookie("theme")
                                          setThemeCookie("theme", newTheme)
                                      }}
                            >


                                {context?.theme === 'dark' ? <BsMoonStars size={18} title="Switch to light theme"/> :
                                    <BsSun size={20} title="Switch to dark theme"/>}
                            </Nav.Link>


                            {AuthService.isLoggedIn() ?
                                <>
                                    <Nav className="d-none d-lg-inline my-auto">
                                        <NavDropdown className="align-content-center"
                                                     id="nav-dropdown-account-menu"
                                                     title={user !== null && user != undefined ?
                                                         <Image src={user?.thumbnail} height={32} roundedCircle
                                                                className="pe-1"/> : 'My Account'}
                                                     menuVariant="dark"
                                                     align="end"
                                        >
                                            <NavDropdown.Item href="/profile/edit">Settings</NavDropdown.Item>
                                            <NavDropdown.Item href="/help">Help</NavDropdown.Item>
                                            <hr className="light my-1"/>
                                            <NavDropdown.Item onClick={logout}>Sign Out</NavDropdown.Item>

                                {/*            <NavDropdown.Item
                                                className="modeswitch-item d-flex justify-content-between gap-3 align-items-center">


                                                <span>Mode:</span>
                                                <Button
                                                    className={`btn-modeswitch nav-link text-primary-hover ${context?.theme === 'light' ? 'active' : ''}`}
                                                    title="Light"
                                                    onClick={() => {
                                                        console.log("light")
                                                        console.log("context theme: " + context?.theme)
                                                        context?.setTheme("light")
                                                        removeThemeCookie("theme")
                                                        setThemeCookie("theme", "light")
                                                    }}>
                                                    <BsSun/>
                                                </Button>
                                                <Button
                                                    className={`btn-modeswitch nav-link text-primary-hover  ${context?.theme === 'dark' ? 'active' : ''}`}
                                                    title="Dark"
                                                    onClick={() => {
                                                        console.log("dark")
                                                        console.log("context theme: " + context?.theme)
                                                        context?.setTheme("dark")
                                                        removeThemeCookie("theme")
                                                        setThemeCookie("theme", "dark")
                                                    }}>
                                                    <BsMoonStars/>
                                                </Button>

                                            </NavDropdown.Item>*/}
                                        </NavDropdown>
                                    </Nav>

                                </>
                                :
                                <>
                                    <Nav.Link className="d-none d-lg-inline-block align-content-center me-2"
                                              eventKey={2} href="/about">
                                        About
                                    </Nav.Link>
                                    {/*<Nav.Link className="d-none d-lg-inline-block" href="/faq">FAQs</Nav.Link>*/}
                                    <Nav.Link className="d-none d-lg-inline-block align-content-center me-2"
                                              href="/help">Help</Nav.Link>

                                    <div className="d-none d-md-flex flex-row">
                                        <a href="/sign-in">
                                            <Button variant="outline-light"
                                                    className=" text-nowrap"> Login</Button>
                                        </a>
                                        <a href="/sign-up">
                                            <Button variant="primary"
                                                    className=" mx-2 text-nowrap"> Sign-up</Button>
                                        </a>
                                    </div>
                                </>
                            }
                        </Nav>


                        {/*side menu*/}

                        <OffcanvasMenu/>


                    </Col>
                </Row>


            </Container>
        </Navbar>


    );
}
/*
export default Header;*/
