import React, {useEffect, useState} from "react";
import {Button, Form, Nav, Spinner} from "react-bootstrap";
import * as yup from "yup";
import {Formik} from "formik";
import InputGroup from "react-bootstrap/InputGroup";
import {FaEye, FaEyeSlash, FaGoogle} from "react-icons/fa";
import {Navigate, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import AuthService from "../../../service/auth/AuthService";
import axios from "axios";


const SignInComponent = (props: any) => {

    const redirectTo = props?.redirectTo;



    type State = {
        redirect: string | null,
        username: string,
        password: string,
        loading: boolean,
        message: string
    };

    const initialState: State = {
        redirect: null,
        username: "",
        password: "",
        loading: false,
        message: ""
    }





/*        useEffect(() => {
            if (AuthService.isLoggedIn()) {
                const s = initialState;
                s.redirect = "/profile";
                setState(s);
            }

        });*/

    const [state, setState] = useState(initialState);
    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();

    function handleLogin(formValue: { username: string; password: string }) {
        const {username, password} = formValue;
        const s: State = initialState;
        s.loading = true;
        setState(s)
    }

    //const displayToast = () => toast.success('Here is your toast.', {position: "top-right"});

    const validationSchema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required()
    });


    const initialValues = {
        username: "sigachev",
        password: "1111",
    };

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    const location = useLocation();

    const navigateHome = () => {
        // 👇️ navigate to /
        navigate('/');
    };



    const content = (
        <div className="text-center text-body">
            <h1 className="mb-2">Sign in</h1>
            <span className="d-block">Do not have an account yet? <a
                href="sign-up">Sign up here</a></span>
            <Formik
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    AuthService.login(values?.username, values.password, redirectTo);
                    setSubmitting(false);
                }
                }
                enableReinitialize
                initialValues={initialValues}
            >
                {({
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      initialValues,
                      touched,
                      errors,

                  }) => (
                    <Form className="mt-4 mb-3" onSubmit={handleSubmit}>

                        <Form.Group className="mb-4" controlId="formUsername">
                            <Form.Control
                                type="username"
                                name="username"
                                autoComplete="username"
                                placeholder="Enter username or email address"
                                size="lg"
                                value={values.username}
                                onBlur={handleBlur} // This apparently updates `touched`
                                onChange={handleChange}
                                /*isValid={touched.username && !errors.username}
                                isInvalid={touched.username && !!errors.username}*/
                            />

                        </Form.Group>

                        <Form.Group className=" mb-4" controlId="formPassword">
                            <InputGroup size="lg">
                                <Form.Control
                                    type={passwordShown ? "text" : "password"}
                                    name="password"
                                    autoComplete="password"
                                    placeholder="Enter password"
                                    size="lg"
                                    value={values.password}
                                    onBlur={handleBlur} // This apparently updates `touched`
                                    onChange={handleChange}
                                    /*isValid={touched.password && !errors.password}
                                    isInvalid={touched.password && !!errors.password}*/
                                />
                                <InputGroup.Text id="show-password">
                                    <Nav.Link onClick={() => {
                                        setPasswordShown(!passwordShown)
                                    }}>
                                        {passwordShown ? <FaEye/> : <FaEyeSlash/>}
                                    </Nav.Link>
                                </InputGroup.Text>

                            </InputGroup>
                        </Form.Group>

                        <div className="lead mb-3 d-sm-flex justify-content-between ">

                            <Form.Group>
                                <Form.Check type="checkbox" id="rememberCheck" label="Remember me?"/>
                            </Form.Group>
                            <a href="/forgot-password">Forgot password?</a>
                            {/*<Nav.Link href="forgot-password">Forgot password?</Nav.Link>*/}
                        </div>

                        <div className="d-grid">
                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                {isSubmitting ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        Please wait...
                                    </> : "Login"}
                            </Button>

                            <Button href={AuthService.googleAuthEndpoint} variant="danger" className="my-2">
                                <FaGoogle className="align-middle" /> &nbsp; Login via Google</Button>

                            {/*<a href={AuthService.googleLink}>Auth with Google</a>*/}

{/*                          <GoogleLogin
                                auto_select
                                onSuccess={credentialResponse => {
                                    console.log(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />;*/}

                        </div>

                    </Form>
                )}
            </Formik>
        </div>


    );


    return (
        <>
            {content}
        </>
    );

}

export default SignInComponent;
