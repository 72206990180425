import React from 'react';
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "./sidebar/Sidebar";
import './layout.css';
import AuthService from "../../service/auth/AuthService";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Button} from "react-bootstrap";

function Layout(props: any) {

    const {children} = props;


    return (
        <>
            <Header/>

            <Container className="main-container">
                <Row>

                    <Col className="d-none d-md-inline-grid col-md-3 col-lg-2 col-xxl-1-5 px-0 h-100">
                        {AuthService.isLoggedIn() ? <Sidebar/> :
                        <div>
                            <ButtonGroup>
                                <Button>Login</Button>
                                <Button variant="outline-info">Sign Up</Button>
                            </ButtonGroup>
                        </div>
                        }
                    </Col>

                    <Col className="col-12 col-md-9 col-lg-10 col-xxl-10-5">
                        {children}
                    </Col>

                </Row>
            </Container>

                <Footer/>
        </>
    )
}


export default Layout;
