import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import * as yup from "yup";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import SignInComponent from "../../../components/public/sign-in/SignInComponent";
import LayoutEmpty from "../../../components/layout/LayoutEmpty";
import Alert from "react-bootstrap/Alert";


const SignInPage = () => {


    type State = {
        redirect: string | null,
        username: string,
        password: string,
        loading: boolean,
        message: string
    };

    const initialState: State = {
        redirect: null,
        username: "",
        password: "",
        loading: false,
        message: ""
    }

    /*    useEffect(() => {
            if (AuthService.isLoggedIn()) {
                const s = initialState;
                s.redirect = "/profile";
                setState(s);
            }

        });*/

    const [searchParams, setSearchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        setErrorMessage(searchParams.get("error") ?? undefined)
    }, []);

    const [state, setState] = useState(initialState);

    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();

    function handleLogin(formValue: { username: string; password: string }) {
        const {username, password} = formValue;
        const s: State = initialState;
        s.loading = true;
        setState(s)
    }

    //const displayToast = () => toast.success('Here is your toast.', {position: "top-right"});

    const validationSchema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required()
    });


    const initialValues = {
        username: "",
        password: "",
    };

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    const location = useLocation();

    const navigateHome = () => {
        // 👇️ navigate to /
        navigate('/');
    };

    const [showAlert, setShowAlert] = useState(true);

    const content = (
        <> {errorMessage &&
            <Alert show={showAlert} variant="danger" onClose={() => setShowAlert(false)} dismissible
                   className="mt-2" style={{position: "absolute", top: 50, zIndex: 100}}>
                    {errorMessage}
            </Alert>
        }
            <Col className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5 align-content-center">
                <Card className="card card-body rounded-3 p-6 p-sm-5 ">
                    <SignInComponent/>
                </Card>
            </Col>
        </>

    );


    return (
        <>
            <LayoutEmpty center>
                {content}
            </LayoutEmpty>
        </>
    );

}

export default SignInPage;
