import React, {useEffect, useState} from "react";
import Layout from "../../components/layout/Layout";
import SearchService from "../../service/SearchService";
import {IPosition} from "../../model/Positions";
import positionsService from "../../service/PositionsService";
import {IUser} from "../../model/User";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import {Button, CardBody, Image, Modal, Table} from "react-bootstrap";

import avatar from "../../assets/images/user/default_avatar.png";
import CardHeader from "react-bootstrap/CardHeader";
import Card from "react-bootstrap/Card";
import SignInComponent from "../../components/public/sign-in/SignInComponent";
import {Link, useLocation} from "react-router-dom";
import StockService from "../../service/StockService";
import {IStockDto} from "../../model/Stock";
import Utils from "../../service/utils/Utils";
import {Cell} from "recharts";
import WatchlistService from "../../service/WatchlistService";


export const PositionsPage = () => {

        const [positions, setPositions] = useState<IPosition[]>([])
        const [showLogin, setShowLogin] = useState(false);
        const handleCloseLogin = () => setShowLogin(false);
        const handleShowLogin = () => setShowLogin(true);
        const location = useLocation();

        useEffect(() => {

                positionsService.getAllForCurrentUser()
                    .then(response => {
                        setPositions(response.data)
                        console.log("positions:: " + positions)
                    })
            }, []
        )

        /*    const { Column, HeaderCell, Cell } = Table;*/

        /*    const renderPositions1 =*/
        /*        <Table
                    height={400}
                    data={positions}
                    onRowClick={rowData => {
                        console.log(rowData);
                    }}
                >
                    <Column width={150}>
                        <HeaderCell>Symbol</HeaderCell>
                        <Cell dataKey="symbol" />
                    </Column>

                    <Column width={150}>
                        <HeaderCell>Price</HeaderCell>
                        <Cell dataKey="currentPrice" />
                    </Column>
                </Table>*/


        const renderPositions =
            <>
                <Table hover responsive>
                    <thead>
                    <tr>
                        <th>Stock</th>
                        <th>Price Paid</th>
                        <th>Market price</th>
                        <th>Qty</th>
                        <th>Total cost</th>
                        <th>Gain</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        positions && positions?.map((p, index) => {
                                console.log("position: " + JSON.stringify(p))
                                //const stock:IStockDto = StockService.getById(p.stockId).response?.data
                                return <tr key={index}>
                                    <td className=" align-middle">
                                        <a className="fw-bolder" href={'/stock/' + p.symbol}>{p.symbol}</a>
                                    </td>
                                    <td className=" align-middle">{p.price}</td>
                                    <td className=" align-middle">{p.currentPrice}</td>
                                    <td className=" align-middle">{p.quantity}</td>
                                    <td className=" align-middle">{Utils.formatPrice(p.price! * p.quantity)}</td>
                                    <td className="align-middle">{(p.currentPrice! * p.quantity) - (p.price! * p.quantity)}</td>
                                    <td className="align-middle">
                                        <Button variant="success" size="sm" className="me-2"
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    /*  handleRemoveModalShow(u)*/
                                                }}
                                        >Buy</Button>
                                        <Button variant="danger" size="sm"
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    /*  handleRemoveModalShow(u)*/
                                                }}
                                        >Sell</Button>
                                    </td>
                                </tr>
                            }
                        )
                    }
                    </tbody>
                </Table>
            </>

        const content =
            <>
                <Modal show={showLogin} onHide={handleCloseLogin} centered size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>Please sign-in to add comments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="m-1 m-md-3 px-2 px-md-4"><SignInComponent redirectTo={location.pathname}/></div>
                    </Modal.Body>
                </Modal>


                <Row className="g-3">
                    <Col sm={12}>

                        <Card>
                            <CardHeader>
                                <h6>My positions</h6>
                            </CardHeader>

                            <CardBody className="">

                                {renderPositions}

                                {/*{renderPositions1}*/}


                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </>

        return (
            <>
                <Layout>
                    {content}
                </Layout>
            </>
        );
    }
;

export default PositionsPage;