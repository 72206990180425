import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import UserService from "../service/UserService";
import {IUser} from "../model/User";
import {IApexChartData} from "../model/TimeSeries";
import UserConnectionsService from "../service/UserConnectionsService";
import PositionsService from "../service/PositionsService";
import Layout from "../components/layout/Layout";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Badge, CardBody} from "react-bootstrap";
import AccountValueChartComponent from "../components/profile/charts/AccountValueChartComponent";
import UserFinancesService from "../service/UserFinancesService";
import Utils from "../service/utils/Utils";
import ColoredTextComponent from "../components/utils/ColoredTextComponent";
/*import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';*/


const DashboardPage = () => {

    const [userBalance, setUserBalance] = useState<number>()
    const [stocksBalance, setStocksBalance] = useState<number>()

    const userPromise = UserService.getUser()
    const userResponse = userPromise.response
    const userLoading = userPromise.loading
    const user: IUser = userResponse?.data;


    useEffect(() => {
        UserFinancesService.getUserBalance()
            .then(resp => setUserBalance(resp.data))

        PositionsService.getPositionsValue().
            then(resp => setStocksBalance(resp.data))


    }, []);


    const content =
        <Card className="mb-3">
            <Card.Body>

                <Row className="row g-3 mb-3">
                    <Col className="col-sm-6 col-md-4">
                        <Card className="overflow-hidden">
                            <CardBody className="position-relative">
                                <h6>Cash<Badge className="bg-danger rounded-pill ms-2">-0.23%</Badge></h6>
                                <div className="display-4 fs-4 mb-2 fw-medium text-success">{userBalance && Utils.formatPrice(userBalance, 2, true)}</div>
                                <a className="fw-semibold fs-6 text-nowrap" href="/wallet">My wallet</a>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col className="col-sm-6 col-md-4">
                        <Card className="overflow-hidden">
                            <CardBody className="position-relative">
                                <h6>Stocks Value<Badge className="rounded-pill ms-2">0.0%</Badge></h6>
                                <div className="display-4 fs-4 mb-2 fw-medium text-success">{stocksBalance && Utils.formatPrice(stocksBalance, 2, true)}</div>
                                <a className="fw-semibold fs-10 text-nowrap" href="/positions">Details</a>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col className="col-md-4">
                        <Card className="card overflow-hidden">
                            <CardBody className="card-body position-relative">
                                <h6>Total <Badge className="rounded-pill ms-2">9.54%</Badge></h6>
                                <div className="display-4 fs-4 mb-2 fw-medium text-danger">
                                    <ColoredTextComponent input={userBalance && stocksBalance &&  Utils.formatPrice(userBalance + stocksBalance, 2, true)}/>
                                </div>
                                <a className="fw-semibold fs-10 text-nowrap" href="/#">Statistics</a>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

{/*                <Row>
                    <Col>
                        <Gauge
                            value={75}
                            startAngle={-110}
                            endAngle={110}
                            sx={{
                                [`& .${gaugeClasses.valueText}`]: {
                                    fontSize: 40,
                                    transform: 'translate(0px, 0px)',
                                },
                            }}
                            text={
                                ({ value, valueMax }) => `${value} / ${valueMax}`
                            }
                        />
                    </Col>
                </Row>*/}

            </Card.Body>
        </Card>


    return <Layout>{content}</Layout>

}

export default DashboardPage;